.actions-box {
  margin-top: 50px;
}

.actions-box .actions-title {
  margin: 0;
  font-size: 32px;
  line-height: 40px;
}

.actions-box .actions-title span {
  text-transform: capitalize;
  font-size: 28px;
  font-weight: 600;
}

.actions-box .balance {
  font-size: 18px;
  margin-top: 30px;
}

.actions-box .actions-button-box {
  margin-top: 30px;
}

.actions-box .action-buttons {
  margin-top: 30px;
}

.actions-box .action-button {
  background-image: linear-gradient(90deg, #b04aee, #8239fa);
  display: inline-block;
  padding: 20px;
  vertical-align: top;
  margin: 10px;
  border-radius: 4px;
  cursor: pointer;
  font-size: 16px;
  font-weight: 600;
  text-decoration: none;
  border: none;
  color: #ffffff;
  transition: background-image 0.3s ease;
}

.actions-box .action-button:hover {
  background-image: linear-gradient(90deg, #222, #131313);
}
