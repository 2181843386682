.landing-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
}

.landing-wrapper .landing-wrapper-inner {
  width: 100%;
  max-width: 600px;
  margin: 0 auto;
  padding: 20px;
  text-align: center;
}

.landing-wrapper .landing-title {
  margin: 0;
  font-size: 32px;
  line-height: 40px;
}

.landing-wrapper .connect-button {
  background-image: linear-gradient(90deg, #b04aee, #8239fa);
  display: flex;
  padding: 10px 24px;
  align-items: center;
  margin: 50px auto 0;
  border-radius: 8px;
  cursor: pointer;
  font-size: 18px;
  font-weight: 700;
  text-decoration: none;
  border: none;
  color: #ffffff;
}
