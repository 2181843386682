.haeder-box {
  margin-bottom: 20px;
}

.haeder-box .haeder-box-inner {
  display: flex;
  width: 100%;
  align-items: flex-start;
  justify-content: space-between;
  flex-wrap: wrap;
}

.haeder-box .account-info {
  display: flex;
  align-items: center;
}

.haeder-box .account-info .image {
  margin-right: 10px;
}

.haeder-box .account-info .address {
  font-size: 18px;
  line-height: 24px;
  font-weight: 600;
}

.haeder-box .chain-select-box {
  display: flex;
  align-items: center;
  font-size: 20px;
}
.haeder-box .chain-select-box span {
  margin-right: 10px;
}

.haeder-box .chain-select-box select {
  width: 150px;
  font-size: 16px;
  padding: 8px 14px;
  border-radius: 4px;
  border: 1px solid #222222;
}

.haeder-box .disconnect-button {
  background-color: #222;
  color: #fff;
  display: flex;
  padding: 10px 20px;
  margin: 0;
  border-radius: 4px;
  cursor: pointer;
  font-size: 14px;
  font-weight: 400;
  text-decoration: none;
  border: none;
  transition: background-color 0.3s ease;
}

.haeder-box .disconnect-button:hover {
  background-color: #131313;
}

.haeder-box .account-addresses-box {
  flex: 0 0 100%;
  margin-top: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 20px;
}


.haeder-box .account-addresses-box span {
  margin-right: 10px;
}

.haeder-box .account-addresses-box select {
  width: 300px;
  font-size: 16px;
  padding: 8px 14px;
  border-radius: 4px;
  border: 1px solid #222222;
}
