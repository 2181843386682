.balance-wrapper {
  display: flex;
  justify-content: center;
  min-height: 100vh;
}

.balance-wrapper .balance-wrapper-inner {
  width: 100%;
  max-width: 900px;
  margin: 0 auto;
  padding: 20px;
  text-align: center;
}
